import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import SecondaryLanding from "../../../../layouts/secondary-landing";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";
import Icon from "../../../../components/custom-widgets/icon";
import HeroChevron from "../../../../components/hero/hero-chevron";
import NotificationAlert from "../../../../components/notifications/notification-alert";

import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";

const HomeownerAssistanceSpanish = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "homeowner-assistance-hero",
    ...getHeroImgVariables(imgData),
    altText: "Padre y su hijo riendo y hablando en el porche de su casa.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Asistencia para propietarios de vivienda"
          }
        },
        {
          id: 2,
          button: {
            id: "homeowner-assistance-hero-cta",
            text: "Aprende cómo aplicar",
            url: "#como-aplicar-section",
            class: "btn-white",
            type: "anchor-link"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      url: "/es/banca-personal/prestamos-hipotecarios",
      title: "Préstamos Hipotecarios"
    },
    {
      id: 3,
      active: true,
      title: "Asistencia para propietarios de viviendas"
    }
  ];

  const SEOData = {
    title: "Asistencia para propietarios de viviendas",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Asistencia para propietarios de viviendas"
      },
      {
        name: "description",
        property: "og:description",
        content: "Programas de asistencia para propietarios de viviendas de WaFd Bank"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/prestamos-hipotecarios/asistencia-a-propietarios"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const TitleSection = () => {
    return (
      <section className="container">
        <h1>Asistencia para propietarios de vivienda de WaFd bank</h1>
        <span className="h3">
          Asistencia del departamento de propietarios de vivienda están aquí para ayudar a nuestros clientes
          hipotecarios que están experimentando dificultades financieras. Existen diversas opciones que podrían estar
          disponibles, tales como:
        </span>
      </section>
    );
  };

  const OptionsColumn = ({ options }) => {
    return options.map(({ title, text }) => {
      return (
        <div className="options-column">
          <h5 className="m-0">{title}</h5>
          {text && <p>{text}</p>}
        </div>
      );
    });
  };

  const OptionSection = ({ customClass, title, optionsLeft, optionsRight }) => {
    return (
      <section className={"container " + customClass}>
        <h3 className="text-success font-weight-bold">{title}</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            <OptionsColumn options={optionsLeft} />
          </div>
          <div className="col-12 col-md-6">
            <OptionsColumn options={optionsRight} />
          </div>
        </div>
      </section>
    );
  };

  const HowToApplySection = () => {
    return (
      <section id="como-aplicar-section" className="container">
        <h3 className="text-success font-weight-bold">Cómo aplicar</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            <p>
              A continuación encontrará nuestro formulario de solicitud y una lista de documentos requeridos. Es posible
              que se soliciten documentos adicionales o información durante la revisión de su solicitud. Si no puede
              acceder al formulario en el enlace proporcionado, contáctenos y se lo enviaremos.
            </p>
            <p>
              <strong>Por favor tenga en cuenta:</strong> Los préstamos garantizados por segundas viviendas o
              propiedades de alquiler, líneas de crédito con garantía hipotecaria (HELOCs), préstamos para terrenos y
              otros préstamos personales no son elegibles para la Asistencia de Propietarios de Vivienda.
            </p>
            <ul>
              <li>
                <a
                  id="ha-application-doc-link"
                  className="underline"
                  href="/documents/homeowner-assistance-application-010523.pdf"
                  target="_blank"
                  title="Solicitud de asistencia para propietario de vivienda"
                >
                  Solicitud de asistencia para propietario de vivienda
                </a>
                , completamente llenada y firmada
              </li>
              <li>Talones de pago, si corresponde, de los últimos dos meses</li>
              <li>Todos los formularios W-2 recibidos del año anterior</li>
              <li>
                Todos los estados de cuenta bancarios y de cuentas de inversión, excluyendo cuentas de jubilación, de
                los últimos dos meses
              </li>
              <li>
                <div className="mb-1">Si es trabajador autónomo y/o recibe ingresos de alquiler:</div>
                <ul className="mt-2">
                  <li>Declaraciones de impuestos firmadas de los últimos dos (2) años con todos los anexos</li>
                  <li>
                    Estado de resultados y balance general al final del año fiscal anterior y hasta la fecha del año en
                    curso (Estado de situación financiera de ganancias y pérdidas ) de cualquier entidad de la cual
                    usted sea propietario
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <NotificationAlert
              type="warning"
              id="email-error-notification-alert"
              bodyText="Porque valoramos su privacidad, por favor no envíe los documentos de la solicitud por correo electrónico. Cuando esté listo para enviar su solicitud, puede enviarnos un correo electrónico para obtener un enlace seguro y enviarla electrónicamente."
            />
            <p>
              Alternativamente, puede entregar el paquete en su sucursal local de WaFd Bank o enviarlo por correo a la
              dirección que se proporciona a continuación. Si tiene alguna pregunta o necesita ayuda, por favor envíenos
              un correo electrónico a{" "}
              <a id="ha-email-link" href="mailto:homeownerassistance@wafd.com">
                homeownerassistance@wafd.com
              </a>{" "}
              o llámenos al{" "}
              <a id="ha-tel-link" href="tel:866-453-9710">
                866-453-9710
              </a>
              .
            </p>
            <p className="font-weight-bold">
              WaFd Bank <br />
              Atención Homeowner Assitance Department <br />
              425 Pike Street <br />
              Seattle, WA 98101
            </p>
          </div>
        </div>
      </section>
    );
  };

  const AdditionalResource = ({ title, tel, externalLink, id }) => {
    return (
      <div id={`${id}-resource`} className="col-12 col-md-6 mb-2 mb-md-0">
        <h5>{title}</h5>
        <div>
          <Icon lib="fal" name="phone-alt" fixedWidth="1.75rem" containerClass="mr-1 float-left text-primary" />{" "}
          <a id={`${id}-phone`} href={`tel:${tel}`} className="d-block overflow-hidden text-decoration-none">
            {tel}
          </a>
        </div>
        <div>
          <Icon lib="fal" name="external-link" fixedWidth="1.75rem" containerClass="mr-1 float-left text-primary" />{" "}
          <a id={`${id}-external-link`} href={externalLink} className="d-block overflow-hidden text-decoration-none">
            {externalLink}
          </a>
        </div>
      </div>
    );
  };

  const AdditionalResourcesSection = () => {
    return (
      <section className="bg-light">
        <div className="container">
          <h2>Recursos adicionales</h2>
          <div className="row">
            <div className="col-12 col-md-6">
              <p>
                Para obtener una lista de agencias de asesoramiento de vivienda aprobadas por HUD que puedan
                proporcionar información sobre prevención de ejecuciones hipotecarias, contacte a una de las siguientes
                agencias del gobierno federal:
              </p>
            </div>
          </div>
          <div className="row">
            {additionalResourcesData.map((resource) => {
              return <AdditionalResource {...resource} />;
            })}
          </div>
        </div>
      </section>
    );
  };

  const optionsKeeping = {
    customClass: "pt-0",
    title: "Opciones para mantener su hogar",
    optionsLeft: [
      {
        title: "Plan de pago",
        text: "El plan de pago le permite pagar los pagos atrasados de la hipoteca, junto con su pago mensual regular, en cuotas a lo largo del tiempo. Durante el período de pago, tendrá un pago mensual de hipoteca más alto."
      },
      {
        title: "Modificación por dificultades",
        text: "Una modificación de préstamo hipotecario proporciona un cambio temporal a los términos existentes del préstamo. Se modifica el pago mensual de la hipoteca ajustando la amortización, la tasa de interés, o ambas."
      },
      {
        title: "Refinanciamiento ",
        text: "El préstamo hipotecario se puede refinanciar tanto con WaFd Bank como con otro prestamista."
      }
    ],
    optionsRight: [
      {
        title: "Plan de indulgencia",
        text: "El plan de indulgencia suspende los pagos regulares mensuales de la hipoteca por un período designado, lo que le permite tiempo para mejorar o estabilizar las dificultades financieras. Los pagos omitidos no son perdonados y se consideran como atrasados hasta que el préstamo se ponga al día. Todos los pagos omitidos deberán ser pagados al final del período de indulgencia."
      },
      {
        title: "Diferimiento",
        text: "Diferimiento de préstamo hipotecario a corto plazo debido a dificultades experimentadas por una emergencia declarada. El programa se originó en respuesta a la pandemia de COVID-19, pero también puede aplicarse a situaciones donde se haya declarado un estado de emergencia, como incendios, inundaciones o mal tiempo generalizado. Los pagos diferidos del préstamo se añaden al final del préstamo para ser pagados en una única cuota al momento de liquidar o vencer el préstamo (excluyendo impuestos y seguros mensuales de la cuenta de depósito en garantía)."
      }
    ]
  };

  const optionsSelling = {
    customClass: "py-0",
    title: "Opciones para vender o transferir su hogar",
    optionsLeft: [
      {
        title: "Venta corta",
        text: "La vivienda que garantiza la hipoteca puede venderse por menos de lo que debe. Revisaremos su solicitud para determinar el monto y los términos de pago para el saldo restante."
      },
      {
        title: "Vender su hogar por más del saldo de la hipoteca",
        text: "Un plan de indulgencia que suspende los pagos mensuales regulares de la hipoteca por un período designado, permitiéndole tiempo para vender su casa. Los pagos omitidos no son perdonados y se consideran como atrasados hasta que el préstamo se pague por completo."
      }
    ],
    optionsRight: [
      {
        title: "Escritura en lugar de ejecución hipotecaria",
        text: "La propiedad de la vivienda que garantiza la hipoteca puede ser transferida al WaFd Bank a un valor acordado. Revisaremos su solicitud para determinar el monto y los términos de pago para el saldo restante."
      }
    ]
  };

  const additionalResourcesData = [
    {
      id: "hud-counseling",
      title: "The U.S. Department of Housing and Urban Development (HUD)",
      tel: "800-569-4287",
      externalLink: "https://www.hud.gov/counseling"
    },
    {
      id: "consumerfinance-mortgagehelp",
      title: "The Consumer Financial Protection Bureau (CFPB)",
      tel: "855-411-2372",
      externalLink: "https://www.consumerfinance.gov/mortgagehelp"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <TitleSection />

      <OptionSection {...optionsKeeping} />
      <OptionSection {...optionsSelling} />

      <HowToApplySection />
      <AdditionalResourcesSection />
    </SecondaryLanding>
  );
};

export default HomeownerAssistanceSpanish;

